import {Component, OnInit} from '@angular/core';
import {ConnectionService} from 'ng-connection-service';
import {SnackService} from '../../snack/snack.service';

@Component({
  selector: 'wifi-connection',
  templateUrl: './wifi-connection.component.html',
  styleUrls: ['./wifi-connection.component.scss']
})
export class WifiConnectionComponent implements OnInit {
  isConnected: boolean = true;
  constructor(private connectionService: ConnectionService,private snack:SnackService) {


  }

  ngOnInit(): void {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        console.log(this.isConnected)
        this.snack.showSnack('COMMON.InternetConnected','success')
      }
      else {
        this.snack.showSnack('COMMON.PleaseInternet','error')
      }
    })
  }
}
