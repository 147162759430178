import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {delay, take, takeUntil} from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';


interface Assign {
  id: string;
  name: string;
  shiftName: string;
}

@Component({
  selector: 'multi-assign',
  templateUrl: './multi-autocomplete-assign.component.html',
  styleUrls: ['./multi-autocomplete-assign.component.scss']
})

export class MultiAssign implements OnInit, AfterViewInit, OnDestroy {

  public assignFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredAssign: ReplaySubject<Assign[]> = new ReplaySubject<Assign[]>(1);

  @ViewChild('multiSelect') multiSelect: MatSelect;
  protected _onDestroy = new Subject<void>();


  @Input() set data(data: any[]) {
    this._data = data;
    this.filteredAssign.next(this.data?.slice());
  }

  get data(): any[] {
    return this._data;
  }


  private _data: any[];

  @Output() result: EventEmitter<any> = new EventEmitter<any>();
  @Input() outlineLabel: String;
  @Input() itemMultiCtrl: UntypedFormControl;
  @Input() optionName: string;

  account: any = {};

  constructor() {
  }

  ngOnInit() {
    this.assignFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy), delay(1000))
      .subscribe(() => {
        this.filterBranches();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChange($event) {
    this.result.emit($event);
  }


  protected setInitialValue() {
    this.filteredAssign
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: Assign, b: Assign) => a && b && a.id === b.id;
      });
  }


  protected filterBranches() {
    if (!this.data) {
      return;
    }

    let search = this.assignFilterCtrl.value;
    if (!search) {
      this.filteredAssign.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // this.filteredAssign.next(
    //   this.data.filter(assign => assign.name.toLowerCase().indexOf(search) > -1)
    // );

    this.filteredAssign.next(
      this.data.filter(assign => {
        if(assign?.shiftName?.length > 0){
          return assign?.shiftName?.toLowerCase()?.indexOf(search) > -1;
        }
        if(assign?.name?.length > 0){
          return assign?.name?.toLowerCase()?.indexOf(search) > -1;
        }

      })
    );

  }
}
