import {Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {Overlay} from '@angular/cdk/overlay';
import {MatButton} from '@angular/material/button';
import {TranslateService} from '@ngx-translate/core';
import {HumanResourcesService} from '../service/human-resources.service';
import {SnackService} from '../../../shared/components/snack/snack.service';
import {AlertLocationComponent} from '../../point-of-sale/alert-location/alert-location.component';
import {AlertTimeComponent} from '../../point-of-sale/alert-time/alert-time.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmComponent} from '../../point-of-sale/confirm/confirm.component';
import moment from 'moment';
import {MatSelectChange} from '@angular/material/select';
import {Constant} from '../../../shared/constant/constant';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {ItemAttach} from '../../../shared/modal/inventory/item/itemAttach';

@Component({
  selector: 'app-employee-check-in-out',
  templateUrl: './employee-check-in-out.component.html',
  styleUrls: ['./employee-check-in-out.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeCheckInOutComponent implements OnInit {
  @ViewChild('checkInOutOrigin') private _checkInOutOrigin: MatButton;
  @ViewChild('checkInOutPanel') private _checkInOutPanel: TemplateRef<any>;
  date = new Date().toLocaleString().replace(/(.*)\D\d+/, '$1');
  currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
  private subscriptions = new Subscription();
  isLoaded: boolean = false;
  upload_Avatar = new Subject();
  branchImageUrl: string;
  branchID: number;
  itemAttachments: ItemAttach[] = [];
  branch = JSON.parse(localStorage.getItem('BRANCH_CONFIG'));
  userTypes = this.currentUser.userTypes?.map((userType) => userType.code);
  currentCheckStartTime;
  currentCheckDetails;
  currentCheckDuration;
  checkedInStatus: boolean = false;
  empLocation = {};
  branchLocation = {
    lat: this.branch.lat,
    lon: this.branch.lon,
  };
  maxEmpDistance: number = this.branch.allowedMetersTocheckIn;
  shiftDetails: any;
  checkInOrOutByEmpIdInput: boolean = false;
  checkInOrOutByCode: boolean = false;
  employeeCheckForm: UntypedFormGroup;
  qrCodeValue;
  checkedIn: boolean = false;

  constructor(private _overlay: Overlay,
              public _translate: TranslateService,
              public _hrService: HumanResourcesService,
              private snack: SnackService,
              public dialog: MatDialog,
              private ItemDialogRef: MatDialogRef<EmployeeCheckInOutComponent>,
              public constants: Constant,
              private fb: FormBuilder
  ) {
    this.getAttendance();
    this.employeeCheckForm = this.fb.group({
      employeeId: ['', Validators.required],
    });
  }


  ngOnInit(): void {
  }

  closePanel(): void {
    this.ItemDialogRef.close();
  }

  getAttendance() {
    if (this.userTypes.includes('EMP') || this.userTypes.includes('SUPERVISOR')) {
      this._hrService.getAttendanceStatus().subscribe((res) => {
        if (!res.data) {
          this.checkedInStatus = false;
          this.getEmployeeShifts(this.branch.id);
        } else {
          this.currentCheckDetails = res.data;
          this.currentCheckStartTime = res.data.createdDate;
          this.getEmployeeShifts(this.branch.id);
          this.getDuration();
          this.checkedInStatus = true;
        }
      });

    }
  }

  checkIn() {
    navigator.geolocation.getCurrentPosition((position) => {
      if (this.branch.enableGeolocationValidation) {
        if (this.isAllowedDistance(position)) {
          if (this.validTime()) {
            this._hrService.checkIn(this.branch.id, this.shiftDetails.id).subscribe((res) => {
              this.snack.showSnack(res.data, 'success');
              this.getAttendance();
            });
          } else {
            this.openTimeAlert();
          }
        } else {
          this.openAlert();
        }
      } else {
        if (this.validTime()) {
          this._hrService.checkIn(this.branch.id, this.shiftDetails.id).subscribe((res) => {
            this.snack.showSnack(res.data, 'success');
            this.getAttendance();
          });
        } else {
          this.openTimeAlert();
        }
      }

    }, () => {
      this.openLocationAlert();
    });

  }

  checkOut() {
    this._hrService.checkOut().subscribe((res) => {
      this.snack.showSnack(res.data, 'success');
      this.currentCheckDetails = null;
      this.currentCheckStartTime = null;
      this.shiftDetails = null;
      this.checkedInStatus = false;
    });
  }

  isAllowedDistance(position) {
    this.empLocation = {
      lat: position.coords.latitude,
      lon: position.coords.longitude,
    };
    let empDistance = this.getDistanceBetweenTwoPoints(this.branchLocation, this.empLocation);
    return empDistance <= this.maxEmpDistance;
  }

  getDistanceBetweenTwoPoints(cord1, cord2) {
    if (cord1.lat == cord2.lat && cord1.lon == cord2.lon) {
      return 0;
    }

    const radlat1 = (Math.PI * cord1.lat) / 180;
    const radlat2 = (Math.PI * cord2.lat) / 180;

    const theta = cord1.lon - cord2.lon;
    const radtheta = (Math.PI * theta) / 180;

    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
      dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    //dist = dist * 1.609344; //convert miles to km
    dist = dist * 1609.34; //convert miles to meter
    dist = Math.round(dist);
    console.log(dist);
    return dist;
  }

  openLocationAlert() {
    const dialogRef = this.dialog.open(AlertLocationComponent, {
      disableClose: true,
      width: '400px',
      direction: this._translate.currentLang === 'ar' ? 'rtl' : 'ltr',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result)) {

      }
    });
  }

  openAlert() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '400px',
      direction: this._translate.currentLang === 'ar' ? 'rtl' : 'ltr',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result)) {
      }
    });
  }

  openTimeAlert() {
    const dialogRef = this.dialog.open(AlertTimeComponent, {
      disableClose: true,
      width: '400px',
      direction: this._translate.currentLang === 'ar' ? 'rtl' : 'ltr',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result)) {
      }
    });
  }

  validTime() {
    let startTime = this.shiftDetails?.startTime;
    let endTime = this.shiftDetails?.endTime;

    let currentDate = new Date();

    let startDate = new Date(currentDate.getTime());
    startDate.setHours(startTime?.split(':')[0]);
    startDate.setMinutes(startTime?.split(':')[1]);
    startDate.setSeconds(startTime?.split(':')[2]);
    let endDate = new Date(currentDate.getTime());
    endDate.setHours(endTime?.split(':')[0]);
    endDate.setMinutes(endTime?.split(':')[1]);
    endDate.setSeconds(endTime?.split(':')[2]);
    let subtract = endDate.getTime() - startDate.getTime();

    // true,add day
    if (subtract < 0) {
      endDate.setDate(endDate.getDate() + 1);
    }
    return startDate < currentDate && endDate > currentDate;
  }

  getDuration() {
    let StartTime = new Date(this.currentCheckStartTime);
    let time = moment.duration(moment(StartTime).format('HH:mm'));
    let date = moment(new Date()).utc();
    date.subtract(time);
    this.currentCheckDuration = date.format('HH:mm');
  }

  getEmployeeShifts(branchId) {
    this._hrService.getEmployeeShifts(branchId).subscribe((res) => {
      this.shiftDetails = res.data;
    });
  }


  checkInById() {
    let employeeId;
    if (this.checkInOrOutByEmpIdInput) {
      employeeId = this.employeeCheckForm.get('employeeId').value;
    } else if (this.checkInOrOutByCode) {
      employeeId = this.qrCodeValue.employeeId;
    }
    this._hrService.CanCheckInByEmpId(employeeId).subscribe((res) => {
      if (res) {
        if (res.data === true) {
          this._hrService.checkInEmployeeById(this.branch.id, employeeId).subscribe((res) => {
            this.checkedIn =true;
          });
        } else {
          this._hrService.checkOutEmployeeById(employeeId).subscribe((res) => {
            this.checkedIn =false;
          });
        }
      }
    });
  }

  addQrCodeValue(value: string) {
    this.qrCodeValue = value;
    this.checkInById()
  }

  removeQrValue() {
    this.qrCodeValue = null;
  }

  serviceNotAvailable() {
    this.snack.showSnack('COMMON.NOT_AVAILABLE', 'info');
  }
}
