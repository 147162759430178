import {TranslateService} from '@ngx-translate/core';
import {SnackComponent} from './snack.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SnackService {
  constructor(
    private _snackBar: MatSnackBar,
    public _translate: TranslateService
  ) { }

  showSnack(msg, type): void {
    const message = this._translate.instant(msg);
    const options: any = {};
    if (type === 'success') {
      options.icon = 'check_circle';
      options.icon_color = '#00C853';
      options.durationInSeconds = 5;
    } else if (type === 'error') {
      options.icon = 'cancel';
      options.icon_color = '#BF360C';
      options.durationInSeconds = 30;
    } else if (type === 'info') {
      options.icon = 'info';
      options.icon_color = '#2196F3';
      options.durationInSeconds = 30;
    }
    else if (type === 'warning') {
      options.icon = 'warning';
      options.icon_color = '#f3b121';
      options.durationInSeconds = 1;
    }
    this._snackBar.openFromComponent(SnackComponent, {
      data: {
        message,
        icon: options.icon,
        icon_color: options.icon_color,
        action_icon: 'close',
      },
      verticalPosition: 'bottom',
      horizontalPosition:
        this._translate.currentLang === 'ar' ? 'left' : 'right',
      duration: options.durationInSeconds * 1000,
    });
  }
}
