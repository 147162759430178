export class SearchRequest {
    public constructor(
        public searchCriteria?:any,
        public pageNumber:number= 0,
        public pageSize:number= 10,
        public sortableColumn:string= 'id',
        public totalElements:number =0
       ) {

    }

}
