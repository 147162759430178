<div class="wifi-button">
  <!-- <img [src]="statusWifi"> -->
  <div class="img">
    <div style="height: 30px" *ngIf="!isConnected">
      <!--          <img style="width: 30px; height: 30px" src="assets/images/no-wifi.png" alt="not-connected">-->
      <span style="color: #22B92D" class="material-icons material-icons-round icon">wifi_off</span>

    </div>
    <div   *ngIf="isConnected">
      <!--          <img style="width: 30px; height: 30px" src="assets/images/wifi.png" alt="connected">-->
      <span style="color: #22B92D" class="material-icons material-icons-round icon">wifi</span>

    </div>
  </div>
</div>
