export const environment = {
  production: true,
  headerStyle:{
	   'background-color':'none',
	   'color':'none' },
prodHeaderStyle: {
	   'background-color': '#FFFFFF',
	   'color': '#e44891'},
	   billIconColor: ' #8731d4',
  apiUrl: 'https://www.5dma.vip/service-5dma/',
  baseImageUrl: 'https://khdma-production-bucket.s3.eu-west-2.amazonaws.com/',
  appToken : 'PORTAL_OF5a6P1iKbVDdrIe5OOvkGxYZo6XT3g0wEKC9PlsoWfNUBf8sZgWt31Jz4L8e7hRMLNxZ8vOYdTJIotb1uwDRX6LbNWRALXzV5iUNjKBomxHCsUkgKKTdVToxIDf8fE3',
  googleTranslateApiKey : 'https://translation.googleapis.com/language/translate/v2?key=AIzaSyD8SgJXnz7LzQAhk02uyQbV-BtnE2ahhc8' ,
  firebase: {
    apiKey: "AIzaSyD8SgJXnz7LzQAhk02uyQbV-BtnE2ahhc8",
    authDomain: "dmaapp-56741.firebaseapp.com",
    databaseURL: "https://dmaapp-56741.firebaseio.com",
    projectId: "dmaapp-56741",
    storageBucket: "dmaapp-56741.appspot.com",
    messagingSenderId: "360289453312",
    appId: "1:360289453312:web:196f1c27e252ff4f9f1fe5",
    measurementId: "G-4TVDLLP6G5"


  }
};
