import {Router} from '@angular/router';
import {catchError, tap} from 'rxjs/operators';
import {BaseService} from './../shared/service/base.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {User} from '../shared/modal/user-management/user';
import {ItemAttach} from '../shared/modal/inventory/item/itemAttach';
import {Item} from '../shared/modal/inventory/item/item';
import {Constant} from '../shared/constant/constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  logInEndPoint = 'api/v1/auth/portal/login';
  registerCustomerEndPoint = 'api/v1/auth/portal/register/customer';
  forgetEndPoint = 'api/v1/auth/send-reset-link';
  checkResetEndPoint = 'api/v1/auth/check-reset-link';
  changePasswordEndPoint = 'api/v1/auth/change-password';
  portalConfigEndPoint = 'api/v1/portal-config/get-config';
  refreshTokenEndPoint = 'api/v1/auth/refresh-access-token';
  logoutEndPoint = 'api/v1/auth/logout';
  REGISTEREndPoint = 'api/v1/auth/register/portal';
  bankList: string = 'api/v1/bank';
  countryEndPoint: string = 'api/v1/bank';
  checkPhoneNumber: string = 'api/v1/auth/check-phone-number?phoneNumber';
  referralSettingEndPoint: string = 'api/v1/customer-branch/referral-setting';
  CustomerBranchesEndPoint: string = 'api/v1/customer-branch/customer';
  public user: Observable<User>;
  userScreens: any[] = [];
  config = new BehaviorSubject(null);
  public deviceId;
  constant = new Constant();

  constructor(private _base: BaseService, private _router: Router) {
    this.user = JSON.parse(localStorage.getItem('CURRENT_USER'));
  }

  login(requestBody: any) {
    let loginReq = {
      login: requestBody.userId,
      password: requestBody.password,
    };

    return this._base.post(loginReq, `${this.logInEndPoint}`).pipe(
      tap(
        (res: any) => {
          if (res?.data?.accessToken?.length) {
            localStorage.setItem('CURRENT_USER', JSON.stringify(res.data));
            localStorage.setItem('BRANCH_CONFIG', JSON.stringify(res.data.branchConfig));
            localStorage.setItem(
              'ACCESS_TOKEN',
              JSON.stringify(res.data.accessToken)
            );
            localStorage.setItem(
              'REFRESH_TOKEN',
              JSON.stringify(res.data.refreshToken)
            );
            this.getCustomerBranches().subscribe((res) => {
              localStorage.setItem('CUSTOMER_BRANCHES', JSON.stringify(res.data));
            });
            this.userScreens = [];
            res.data.userPrivilage.menu.forEach((element) => {
              element.screens.forEach((element) => {
                this.userScreens.push(element);
              });
            });
            localStorage.setItem(
              'USER_SCREENS',
              JSON.stringify(this.userScreens)
            );
            this.requestConfigData();
          }
        },
        (error) => {
        },
        () => {
        }
      )
    );
  }

  register(requestBody: any) {
    return this._base.post(requestBody, `${this.logInEndPoint}`).pipe(
      tap(
        (res: any) =>
          (error) => {
            console.log(error);
          },
        () => {
        }
      )
    );
  }


  registerCustomer(requestBody: any) {
    return this._base.post(requestBody, `${this.registerCustomerEndPoint}`).pipe(
      tap(
        (res: any) =>
          (error) => {
            console.log(error);
          },
        () => {
        }
      )
    );
  }

  /**
   * Verify email
   * @param code
   * @returns
   */
  verifyEmail(code: string): Observable<any> {
    return this._base.post({}, `api/v1/auth/verifiy-email?code=${code}`);
  }

  deleteAccount(requestBody): Observable<any> {
    return this._base.post(requestBody, `api/v1/appuser/delete/request`);
  }

  getCountry() {
    return this._base.get(this.countryEndPoint);
  }

  getUserByPhoneNumber(phoneNumber, userTypeCode) {
    return this._base
      .get(`${this.checkPhoneNumber}=${phoneNumber}&userTypeCode=${userTypeCode}`);
  }

  //get bank list
  getBankList() {
    return this._base.get(this.bankList);
  }

  deleteItemAttach(itemAttachId: number): Observable<Item> {
    return this._base.deleteById(`api/v1/item-attach?id=${itemAttachId}`);
  }


  uploadItemAttachments(attachments: ItemAttach[]): Observable<any> {
    return this._base.post(attachments, 'api/v1/item-attach/all');
  }

  updateNotificationToken(req): Observable<any> {
    return this._base.put(req, 'api/v1/user-notification/update-token');
  }
  disableGuide(){
    return this._base.put({}, 'api/v1/appuser/showTutorialGuide?showTutorial=false');
  }

  requestConfigData() {
    this.configObservable()
      .subscribe((res) => {
        if (res?.status !== 304 && res?.body?.success) {
          this.config.next(res.body.data);
          let lang = localStorage.getItem('LANG');
          localStorage.setItem('CONFIG', JSON.stringify(res.body.data));

          if (lang === "ar"){
            localStorage.setItem('CONFIG_ar', JSON.stringify(res.body.data));
          }else{
            localStorage.setItem('CONFIG_en', JSON.stringify(res.body.data));
          }
        }
      });
  }

  configObservable(): Observable<any> {
    return this._base
      .get(`${this.portalConfigEndPoint}`, null, true);
  }

  forget(email: any): Observable<any> {
    return this._base.get(`${this.forgetEndPoint}?email=${email}`);
  }

  checkResetLink(code: any): Observable<any> {
    return this._base.get(`${this.checkResetEndPoint}?code=${code}`);
  }

  changePassword(query: any): Observable<any> {
    // ?code=${query.code}&newPassword=${query.password}
    return this._base.post(
      query,
      `${this.changePasswordEndPoint}`
    );
  }

  logout() {
    this.logoutMethod().subscribe((res) => {
      const lang = localStorage.getItem('LANG');
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('LANG', lang);
      // this.stopRefreshTokenTimer();
      this._router.navigate(['/index']);
    });
  }

  refreshToken(): Observable<any> {
    let refreshToken = JSON.parse(localStorage.getItem('REFRESH_TOKEN'));
    let refreshReq = {refreshToken: refreshToken, attendToken: false};
    return this._base.post(refreshReq, this.refreshTokenEndPoint).pipe(
      tap((res: any) => {
        if (res?.data?.jwt?.length) {
          localStorage.setItem('ACCESS_TOKEN', JSON.stringify(res.data.jwt));
          // this.startRefreshTokenTimer();
        } else {
          this.logout();
        }
      }, error => {
        console.log('refreshToken >> on error ');
        this.logout();
      })
    );
  }

  logoutMethod(): Observable<any> {
    let refreshToken = JSON.parse(localStorage.getItem('REFRESH_TOKEN'));
    let refreshReq = {refreshToken};
    return this._base.post(refreshReq, `${this.logoutEndPoint}`);
  }

  checkScreenPriv(route: string): Boolean {
    this.userScreens = JSON.parse(localStorage.getItem('USER_SCREENS'));
    if (!this.userScreens) {
      return false;
    }
    let foundElemment = this.userScreens.some(
      (element) => route.indexOf(element.routingPath) >= 0
    );
    return foundElemment;
  }

  /**
   *get countries
   */
  getCountries(): Observable<any> {
    return this.getListData('api/v1/country');
  }


  /**
   *
   * @param endPointUrl
   */
  getListData(endPointUrl: string): Observable<any> {
    return this._base.get(endPointUrl).pipe(
      tap((res) => {
        console.log(
          `Service : ${endPointUrl.split('/').pop()} loaded successfully`,
          res
        );
      }),
      catchError(
        this.handleError<any>(
          `Get ${endPointUrl.split('/').pop()} Failded .. `
        )
      )
    );
  }

  /**
   *error handeler
   * @param operation
   * @param result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getreferralSetting(branchId, accountManagerPhoneNumber, customerPhoneNumber) {
    return this._base
      .get(`${this.referralSettingEndPoint}?branchId=${branchId}&accountManagerPhoneNumber=${accountManagerPhoneNumber}&customerPhoneNumber=${customerPhoneNumber}`);
  }

  getCustomerBranches(): Observable<any> {
    return this._base.get(`${this.CustomerBranchesEndPoint}`);
  }
}
