import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';

interface Assign {
  id: string;
  name: string;
  shiftName: string;
  branchName: string;
}
@Component({
  selector: 'multiple-selection-select',
  templateUrl: './multiple-selection-select-all.component.html',
  styleUrls: ['./multiple-selection-select-all.component.scss']
})
export class MultipleSelectionSelectAllComponent implements OnInit, AfterViewInit, OnDestroy {



  /** control for the MatSelect filter keyword multi-selection */
  public assignFilterCtrl: FormControl<string> = new FormControl<string>('');

  /** list of assigns filtered by search keyword */
  public filteredAssign: ReplaySubject<Assign[]> = new ReplaySubject<Assign[]>(1);

  /** local copy of filtered assigns to help set the toggle all checkbox state */
  protected filteredAssignsCache: Assign[] = [];

  /** flags to set the toggle all checkbox state */
  isIndeterminate = false;
  isChecked = false;

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();


  /** list of banks */
  @Input() set data(data: any[]) {
    this._data = data;
    this.filteredAssign.next(this.data?.slice());
  }

  get data(): any[] {
    return this._data;
  }

  private _data: any[];

  @Output() result: EventEmitter<any> = new EventEmitter<any>();
  @Input() outlineLabel: String;
  @Input() itemMultiCtrl: UntypedFormControl;
  @Input() optionName: string;

  constructor() { }

  ngOnInit() {
    // listen for search field value changes
    this.assignFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
        this.setToggleAllCheckboxState();
      });

    // listen for multi select field value changes
    this.itemMultiCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.setToggleAllCheckboxState();
    });
  }

  onChange($event) {
    console.log($event.value);
    this.result.emit($event.value);
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredAssign.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.itemMultiCtrl.patchValue(val);
          console.log(val);
          this.result.emit(val);
        } else {
          this.itemMultiCtrl.patchValue([]);
          this.result.emit([]);
        }
      });
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredAssign
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: Assign, b: Assign) => a && b && a.id === b.id;
      });
  }

  protected filterBanksMulti() {
    if (!this.data) {
      return;
    }
    // get the search keyword
    let search = this.assignFilterCtrl.value;
    if (!search) {
      this.filteredAssignsCache = this.data.slice();
      this.filteredAssign.next(this.filteredAssignsCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the assigns
    this.filteredAssignsCache = this.data.filter(assign => {
      if (assign?.name?.length > 0){
        return assign.name.toLowerCase().indexOf(search) > -1
      }
      if (assign?.shiftName?.length > 0){
        return assign.shiftName.toLowerCase().indexOf(search) > -1
      }
      if (assign?.branchName?.length > 0){
        return assign.branchName.toLowerCase().indexOf(search) > -1
      }
    });
    this.filteredAssign.next(this.filteredAssignsCache);
  }

  protected setToggleAllCheckboxState() {
    let filteredLength = 0;
    if (this.itemMultiCtrl && this.itemMultiCtrl.value) {
      this.filteredAssignsCache.forEach(el => {
        if (this.itemMultiCtrl.value.indexOf(el) > -1) {
          filteredLength++;
        }
      });
      this.isIndeterminate = filteredLength > 0 && filteredLength < this.filteredAssignsCache.length;
      this.isChecked = filteredLength > 0 && filteredLength === this.filteredAssignsCache.length;
    }
  }

}
