import {ErrorDialogComponent} from './components/error-dialog/error-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {UserMenuComponent} from './components/header/user-menu/user-menu.component';
import {LanguagesComponent} from './components/header/languages/languages.component';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {ProgressBarComponent} from './components/header/progress-bar/progress-bar.component';
import {SnackComponent} from './components/snack/snack.component';
import {HeaderComponent} from './components/header/header.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {UploadFilesComponent} from './components/upload-files/upload-files.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {MomentModule} from 'ngx-moment';
import {SimpleSearchComponent} from './components/simple-search/simple-search.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {LoaderComponent} from './components/loader/loader.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatBadgeModule} from '@angular/material/badge';
import {TruncatePipe} from './pipes/truncate-pipe';
import {AdvancedSearchComponent} from './components/advanced-search/advanced-search.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {WifiConnectionComponent} from './components/header/wifi-connection/wifi-connection.component';
import {NotificationsComponent} from './components/header/notifications/notifications.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {ImageDialogComponent} from './components/image-dialog/image-dialog.component';
import {CameraComponent} from './components/attaches-files/camera/camera.component';
import {WebcamModule} from 'ngx-webcam';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ImageDialogProcessingComponent} from './components/attaches-files/image-dialog-processing/image-dialog-processing.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {PreviewDownloadPdfDialogComponent} from './components/preview-download-pdf-dialog/preview-download-pdf-dialog.component';
import {RouterModule} from '@angular/router';
import {StatusTrackerComponent} from './components/status-tracker/status-tracker.component';
import {InputRestrictionDirective} from './directive/input-restriction.directive';
import {CustomerProgressDialogComponent} from './components/header/customer-progress-dialog/customer-progress-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ReleaseNotesComponent} from './components/release-notes/release-notes.component';
import {MatChipsModule} from '@angular/material/chips';
import {QuillModule} from 'ngx-quill';
import {TrustHtmlPipe} from './pipes/trust-html-pipe';
import {AlertComponent} from './components/alert/alert.component';
import {EmployeeCheckInOutComponent} from '../pages/human-resources/employee-check-in-out/employee-check-in-out.component';
import {AttachesFilesModule} from './components/attaches-files/attaches-files.module';

import {QrScannerComponent} from './components/qr-scanner/qr-scanner.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {FilterAutoComplete} from './components/filter-search-autocomplete/filter-search-autocomplete.component';
import {BranchSearch} from './components/branch-search/branch-search.component';
import {MultiBranch} from './components/multi-autocomplete-branch/multi-autocomplete-branch.component';
import {MultiAssign} from './components/multi-autocomplete-assign/multi-autocomplete-assign.component';
import {MultipleSelectionSelectAllComponent} from './components/multiple-selection-select-all/multiple-selection-select-all.component';
import {SearchAutoCompleteBranch} from './components/search-autocomplete-branch/search-autocomplete-branch.component';
import {TourMatMenuModule} from 'ngx-ui-tour-md-menu';
import { SharedDialogComponent } from './components/shared-dialog/shared-dialog.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SnackComponent,
    ProgressBarComponent,
    SideNavComponent,
    LanguagesComponent,
    UserMenuComponent,
    ErrorDialogComponent,
    UploadFilesComponent,
    SimpleSearchComponent,
    ConfirmDialogComponent,
    ImageDialogComponent,
    LoaderComponent,
    TruncatePipe,
    AdvancedSearchComponent,
    WifiConnectionComponent,
    NotificationsComponent,
    CameraComponent,
    ImageDialogProcessingComponent,
    PreviewDownloadPdfDialogComponent,
    StatusTrackerComponent,
    InputRestrictionDirective,
    CustomerProgressDialogComponent,
    ReleaseNotesComponent,
    TrustHtmlPipe,
    AlertComponent,
    EmployeeCheckInOutComponent,
    QrScannerComponent,
    FilterAutoComplete,
    BranchSearch,
    MultiBranch,
    MultiAssign,
    MultipleSelectionSelectAllComponent,
    SearchAutoCompleteBranch,
    SharedDialogComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    MatStepperModule,
    MatInputModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormsModule,
    MatDividerModule,
    MomentModule,
    MatSelectModule,
    MatDatepickerModule,
    MatListModule,
    CarouselModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSlideToggleModule,
    DragDropModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatBottomSheetModule,
    WebcamModule,
    ImageCropperModule,
    MatRadioModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    QuillModule,
    AttachesFilesModule,
    ZXingScannerModule,
    NgxMatSelectSearchModule,
    TourMatMenuModule,
    MatBadgeModule
  ],
  exports: [
    MatFormFieldModule,
    HttpClientModule,
    TranslateModule,
    HeaderComponent,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatProgressBarModule,
    ProgressBarComponent,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    MatStepperModule,
    MatInputModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormsModule,
    MomentModule,
    SideNavComponent,
    MatDividerModule,
    MatSelectModule,
    MatDatepickerModule,
    UploadFilesComponent,
    MatListModule,
    CarouselModule,
    MatPaginatorModule,
    MatSortModule,
    SimpleSearchComponent,
    MatAutocompleteModule,
    MatExpansionModule,
    ConfirmDialogComponent,
    ImageDialogComponent,
    MatSlideToggleModule,
    LoaderComponent,
    DragDropModule,
    TruncatePipe,
    CommonModule,
    AdvancedSearchComponent,
    MatTooltipModule,
    MatButtonToggleModule,
    MatBottomSheetModule,
    CameraComponent,
    MatRadioModule,
    PreviewDownloadPdfDialogComponent,
    StatusTrackerComponent,
    InputRestrictionDirective,
    QuillModule,
    TrustHtmlPipe,
    AlertComponent,
    NgxMatSelectSearchModule,
    FilterAutoComplete,
    BranchSearch,
    MultiBranch,
    MultiAssign,
    MultipleSelectionSelectAllComponent,
    SearchAutoCompleteBranch,
    TourMatMenuModule,
    MatBadgeModule
  ],
})
export class SharedModule {
}
