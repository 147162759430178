import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss']
})
export class SimpleSearchComponent implements OnChanges {

  @Input() searchInputValue :string;
  @Input() filterHolder :string;
  @Input() pageTitle :string;
  @Output() searchOutputValue = new EventEmitter<string>();
  constructor(public _translate: TranslateService) { }

  /**
   * when page contain more than one tap and you want to reset search after change tap like orders page
   * you can change vavlue by Math.random() in parent component and this will reset search input field
   */
  ngOnChanges() {
    this.searchInputValue = '';
    this.filterHolder = '';
  }

  filterData() {
    setTimeout(() => {
      this.searchOutputValue.emit(this.searchInputValue);
    }, 1000);
  }


}
