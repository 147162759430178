import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'trustHtml'
})
export class TrustHtmlPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}
  transform(value:any): SafeHtml {
    const parser = new DOMParser();
    const document = parser.parseFromString(value, 'text/html');
    return  this.sanitizer.bypassSecurityTrustHtml(document.body.innerText);
  }

}
