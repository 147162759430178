import {ProgressBarService} from './../components/header/progress-bar/progress-bar.service';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  sideNave = new BehaviorSubject<string>(null);
  setbranchConfig = new BehaviorSubject(null);
  url = environment.apiUrl;
  headerStyle = environment.headerStyle;
  prodHeaderStyle = environment.prodHeaderStyle;
  billIconColor = environment.billIconColor;
  production = environment.production;
  userImagePath: String;

  constructor(
    private _http: HttpClient,
    private _progressBarService: ProgressBarService
  ) {
    this.updateUserImage();
  }

  updateUserImage() {
    this.userImagePath =
      environment.baseImageUrl +
      JSON.parse(window.localStorage.getItem('CURRENT_USER'))?.imagePath;
  }

  public get(
    endPoint: string,
    customParams?: HttpParams,
    allowResponse?: boolean
  ): Observable<any> {
    this._progressBarService.show();
    const options: any = {
      responseType: 'json',
      params: !customParams ? null : customParams,
    };
    if (allowResponse) {
      options.observe = 'response';
    }
    return this._http
      .get(endPoint, options)
      .pipe(tap(() => this._progressBarService.hide()));
  }

  public post(requestBody: any, endPoint: string): Observable<any> {
    this._progressBarService.show();
    const options: {} = {
      responseType: 'json',
    };
    return this._http
      .post(endPoint, JSON.stringify(requestBody), options)
      .pipe(tap(() => this._progressBarService.hide()));
  }

  public put(requestBody: any, endPoint: string, optionsParam?): Observable<any> {
    this._progressBarService.show();
    const options: {} = {
      responseType: 'json',
    };
    return this._http
      .put(endPoint, JSON.stringify(requestBody), optionsParam ? optionsParam : options)
      .pipe(tap(() => this._progressBarService.hide()));
  }

  public deleteById(endPoint: string, params?): Observable<any> {
    this._progressBarService.show();
    return this._http.delete<any>(endPoint, {
      observe: 'response',
      params
    }).pipe(tap(() => this._progressBarService.hide()));
  }

  public deleteAllById(
    endPoint: string,
    deletedIdsArr: any[]
  ): Observable<any> {
    this._progressBarService.show();
    return this._http
      .delete<any>(endPoint + `/all?ids=${deletedIdsArr}`, {
        observe: 'response',
      })
      .pipe(tap(() => this._progressBarService.hide()));
  }

  public postFile(requestBody: any, endPoint: string): Observable<any> {
    this._progressBarService.show();
    // const loginUserObj = JSON.parse(sessionStorage.getItem('currentUser'));
    const accessToken = JSON.parse(localStorage.getItem('ACCESS_TOKEN'));
    const jwt: string = 'Bearer ' + accessToken;
    const headers = new HttpHeaders({
      Authorization: jwt,
    });

    const options: {} = { headers, responseType: 'json' };
    return this._http
      .post(endPoint, requestBody, options)
      .pipe(tap(() => this._progressBarService.hide()));
  }

  public downLoad(requestBody: any, endPoint: string): Observable<any> {
    this._progressBarService.show();
    const options: {} = {
      method: 'post',
      responseType: 'blob',
    }; // 2 > ArrayBuffer
    return this._http
      .post(endPoint, JSON.stringify(requestBody), options)
      .pipe(tap(() => this._progressBarService.hide()));
  }

  /**
   * google upload files
   */
  uploadToGoogle(id: number, pathId: string, file: File): Observable<any> {
    console.log(id, pathId, file);
    const formData = new FormData();
    formData.append('file', file);
    return this._http.post<any>(
      `api/v1/upload/cloud/file?id=${id}&pathId=${pathId}`,
      formData
    );
  }

  /**
   * update col status - 1-Draft - ....................
   */
  updateStatus(requestBody: any, entityName: string): Observable<any> {
    return this.put(requestBody, `api/v1/${entityName}/update-status`);
  }

  callGoogleTranslateApi(text, source, target) {
    const body: any = {
      q: text,
      source: source,
      target: target,
    };
    return this._http.post(environment.googleTranslateApiKey, body);
  }
  //
  pdfCurrentDateTimeFormat() {
    let currentdate = new Date();
    return currentdate.getFullYear() + '.' + (currentdate.getMonth() + 1) + '.' + currentdate.getDate() + '.' + ((currentdate.getHours() < 10)?"0":"") + currentdate.getHours() + '.' + ((currentdate.getMinutes() < 10)?"0":"") + currentdate.getMinutes() + '.' + ((currentdate.getSeconds() < 10)?"0":"") + currentdate.getSeconds();
  }
}
