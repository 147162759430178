<div class="page-header">
  <mat-toolbar color="primary">
    <div class="row fw">
        <h2 class="title">
            <mat-icon class="title-icon">ballot</mat-icon>
            <span
              [class.rtl-title-text]="_translate.currentLang === 'ar'"
              [class.ltr-title-text]="_translate.currentLang !== 'ar'"
              >{{ pageTitle | translate }}</span
            >
          </h2>
     
          <div class="search">
            <mat-icon
              [class.rtl-mat-icon]="_translate.currentLang === 'ar'"
              [class.ltr-mat-icon]="_translate.currentLang !== 'ar'"
              >search</mat-icon
            >
            <input
              #filter (keyup)="filterData()"
              [class.naskh]="_translate.currentLang === 'ar'"
              placeholder="{{ 'ORDER.FILTER' | translate }}"
              [(ngModel)]="searchInputValue"
            />
          </div>
          
        </div>
        
    </mat-toolbar>
  </div>
  