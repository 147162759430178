import {BaseService} from './base.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private baseSerive: BaseService
  ) {
  }

  requestPermission() {
    navigator.serviceWorker.register('./firebase-messaging-sw.js')
      .then((registration) => {
        // this.angularFireMessaging.useServiceWorker(registration);
        this.angularFireMessaging.requestToken.subscribe(
          (token) => {
            // console.log(`token ${token}`);
            const oldToken = localStorage.getItem('firebaseToken');
            if (token) {  // to be add this condition with next release   && token != oldToken
              // update database with new token
              this.baseSerive.put({
                token,
                oldToken
              }, 'api/v1/user-notification/update-token').subscribe(res => {
              this.subscribeTokenToTopic(token,'PORTAL_CONSUMER')
              });
              // ----------------------
              localStorage.setItem('firebaseToken', token);
            }
          },
          (err) => {
            console.error('Unable to get permission to notify.', err);
          }
        );
      });
  }

   subscribeTokenToTopic(token, topic) {
    fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'key='+"AAAAU-LsxQA:APA91bFYIzv5IPQA4NaUmuSOmwvE65iayDGzKXLRlKSsE6tSIRxEtYlAePV2wyVjol_aKgMEgoMaFVrHkLwTa36imdGrnRROfJsgddHXjkEVqrZ88s46i0PgPCmcyDAAi39TrjH4oysf"
      })
    }).then(response => {
      if (response.status < 200 || response.status >= 400) {
        throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
      }
    }).catch(error => {
      console.error(error);
    })
  }

  getUserNotifications(): Observable<any> {
    return this.baseSerive.get('api/v1/notification/user');
  }

  updateNotificationIsRead(targetId: number, isRead: boolean, notificationId?: number): Observable<any> {
    return this.baseSerive.get(`api/v1/notification/readed?${targetId ? 'targetId=' + targetId : ''}${notificationId ? '&id=' + notificationId : ''}${'&isRead=' + isRead}`);
  }

  deleteNotification(notificationId) {
    return this.baseSerive.deleteById(`api/v1/notification?id=${notificationId}`);
  }
  readAllNotifications() {
    return this.baseSerive.get('api/v1/notification/read-all');
  }

  receiveMessage(): Observable<any> {
    return this.angularFireMessaging.messages;
  }
  updateApprovalRequest(obj): Observable<any> {
    return this.baseSerive.put(obj, `api/v1/approval-request`);
  }

  updateTransfer(obj): Observable<any> {
    return this.baseSerive.put(obj, `api/v1/transfer-order/change-status`);
  }
}
