import {Subscription} from 'rxjs';
import {AfterContentChecked, ChangeDetectorRef, Component, inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Direction} from '@angular/cdk/bidi';
import {AuthService} from './auth/auth.service';
import {LoaderService} from './shared/components/loader/loader.service';
import {TourMatMenuModule, TourService} from 'ngx-ui-tour-md-menu';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnDestroy, OnInit, AfterContentChecked {
  private subscriptions = new Subscription();
  direction: Direction;
  currentLang: string;
  constructor(
    private cdref: ChangeDetectorRef,
    public _translate: TranslateService,
    private _auth: AuthService,
    public loader: LoaderService,
  ) {
    this.setInitLanguage();
    this.subscriptions.add(
      this._translate.onLangChange.subscribe((res) => {
        this.direction = res.lang === 'ar' ? 'rtl' : 'ltr';
        //localStorage.removeItem('CONFIG');
        this.currentLang = this._translate.currentLang;
        this._auth.requestConfigData();
      })
    );

  }

  ngOnInit() {

    // to update firebase token from mobile app
    document.addEventListener(
      'updateNotificationToken',
      function(e) {
        console.log(' mobile token is >>>', e.detail); // Prints "Example of an event"
        const req = {token: e.detail};
        this.updateNotificationToken(req);
      }.bind(this)
    );
    window.addEventListener('mouseup', function(event) {
      const notificationIcon = document.getElementById('notificationIconId');
      const notificationContent = document.getElementById('notificationContentId');
      if (event.target != notificationIcon) {
        if (event.target != notificationContent) {
          notificationContent?.classList?.remove('showNotificationContent');
        }
      }
    });

  }
  public  tourService = inject(TourService);

  updateNotificationToken(req) {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    if (!accessToken) {
      return;
    }
    this._auth.updateNotificationToken(req).subscribe((res) => {
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  setInitLanguage() {
    const lang = localStorage.getItem('LANG');
    if (lang && lang.length) {
      this._translate.setDefaultLang(lang);
      this._translate.use(lang);
      this.direction = lang === 'ar' ? 'rtl' : 'ltr';
    } else {
      this._translate.setDefaultLang('ar');
      this._translate.use('ar');
      localStorage.setItem('LANG', 'ar');
      this.direction = 'rtl';
    }
    this.currentLang = lang;
    document.body.setAttribute('dir', lang == 'ar' ? 'rtl' : 'ltr');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  end() {
    this.tourService.end();
    this._auth.disableGuide().subscribe((res) => {
     let currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
      currentUser.showTutorialGuide = false
      localStorage.setItem('CURRENT_USER',JSON.stringify(currentUser))
      console.log(res);
    });
  }
}
