import {Injectable} from '@angular/core';
import {BaseService} from '../../../shared/service/base.service';
import * as printJS from 'print-js';

@Injectable({
  providedIn: 'root'
})
export class HumanResourcesService {
// Departments endPoints

  constructor(
    private  _baseService : BaseService
  ) {
  }

  // Departments services
  getDepartments(requestBody) {
    return this._baseService.post(requestBody,`api/v1/department/filter`)
  }
  getDepartmentById(departmentId) {
    return this._baseService.get(`api/v1/department/${departmentId}`)
  }
  addDepartment(requestBody) {
    return this._baseService.post(requestBody,`api/v1/department`)
  }
  editDepartment(requestBody) {
    return this._baseService.put(requestBody,`api/v1/department`)
  }
  enableDepartment(customerBranch,department) {
    return this._baseService.post({customerBranch,department},`api/v1/branch-department`)
  }
  deleteDepartment(branchId,departmentId){
    return this._baseService.deleteById(`api/v1/branch-department/deleteByBranchIdAndDepartmentId?branchId=${branchId}&departmentId=${departmentId}`)
  }

  getBranchDepartment(branchIds){
    return this._baseService.get(`api/v1/branch-department/branch?branchId=${branchIds}`)
  }

// /api/v1/branch-position/filter

  filterBranchPositions(requestBody) {
    return this._baseService.post(requestBody,`api/v1/branch-position/filter`)
  }
  // Positions services
  getPositions(requestBody) {
    return this._baseService.post(requestBody,`api/v1/position/filter`)
  }

  getPositionById(positionId) {
    return this._baseService.get(`api/v1/position/${positionId}`)
  }
  addPosition(requestBody) {
    return this._baseService.post(requestBody,`api/v1/position`)
  }
  editPosition(requestBody) {
    return this._baseService.put(requestBody,`api/v1/position`)
  }
  enablePosition(customerBranch,position) {
    return this._baseService.post({customerBranch , position},`api/v1/branch-position`)
  }
  deletePosition(branchId,positionId){
    return this._baseService.deleteById(`api/v1/branch-position/deleteByBranchIdAndPositionId?branchId=${branchId}&positionId=${positionId}`)
  }

  getBranchPositions(branchIds,branchDepartmentId,branchJobId){
    return this._baseService.get(`api/v1/branch-position/branch/department-job?branchId=${branchIds}&branchDepartmentId=${branchDepartmentId}&branchJobId=${branchJobId}`)
  }



  // jobs services
  getJobs() {
    return this._baseService.get('api/v1/job/customer')
  }
  getJobById(jobId) {
    return this._baseService.get(`api/v1/job/${jobId}`)
  }

  filterJobs(requestBody) {
    return this._baseService.post(requestBody,'api/v1/job/filter')
  }

  getBranchJobs(branchIds) {
    return this._baseService.get(`api/v1/branch-job/branch?branchId=${branchIds}`)
  }

  addJob(requestBody){
    return this._baseService.post(requestBody,`api/v1/job`)
  }
  editJob(requestBody){
    return this._baseService.put(requestBody,`api/v1/job`)
  }

  enableJob(customerBranch,job) {
    return this._baseService.post({customerBranch ,job},`api/v1/branch-job`)
  }

  deleteJob(jobId) {
    return this._baseService.deleteById(`api/v1/branch-job?id=${jobId}`)
  }


  deleteDoubleDuty(doubleDutyId) {
    return this._baseService.deleteById(`api/v1/doubleDuty/delete/${doubleDutyId}`)
  }

// /api/v1/doubleDuty/delete/1008


  // employee check-in-out services
  checkIn(branchId,shiftId){
    return this._baseService.post({},`api/v1/attendance/check-in?branchId=${branchId}&shiftId=${shiftId}`)
  }

  CanCheckInByEmpId(employeeId){
    return this._baseService.put({},`api/v1/attendance/can-checkIn-byEmpId?employeeId=${employeeId}`)
  }

  checkInEmployeeById(branchId,employeeId?){
    return this._baseService.post({},`api/v1/attendance/check-in/employeeId?branchId=${branchId}&employeeId=${employeeId}`)
  }
  checkOutEmployeeById(employeeId){
    return this._baseService.put({},`api/v1/attendance/check-out/employeeId?employeeId=${employeeId}`)
  }

  checkOut() {
    return this._baseService.put({},'api/v1/attendance/check-out')
  }

  getAttendanceStatus(){
    return this._baseService.put({},'api/v1/attendance/has-checkin-or-checkout')
  }

  getEmployeeShifts(branchId) {
    return this._baseService.get(`api/v1/attendance/employee-shift/current?branchId=${branchId}`)
  }

  // google translate
  callGoogleTranslateApi(text, source, target) :any{
    return this._baseService.callGoogleTranslateApi(text, source,target);
  }

  exportTimesheet(req) {
    return  this._baseService.downLoad(req, 'api/v1/attendance/download_time_sheet').subscribe(res => {
      console.log(res);
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'});
      const url = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.download = 'Attendance Time Sheet.xlsx';
        link.click();
        window.URL.revokeObjectURL(url);
    });
  }
}
