<div [style.width.px]="width" style="display: contents" class="toolBar" #fixedHeader>
  <mat-toolbar [ngStyle]="production ? prodHeaderStyle : headerStyle">
    <mat-toolbar-row style="margin: 0 !important;">
      <div class="menu-button">
        <button mat-icon-button (click)="toggleSideNav()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <span class="toolBar-spacer"></span>
      <div style="display: flex ; align-items: center"
           [style.margin-right]="_translate.currentLang !== 'ar' ? '20px' : '0'"
           [style.margin-left]="_translate.currentLang === 'ar' ? '20px' : '0'">
        <div >
          <button  mat-icon-button (click)="start()" >
            <mat-icon
              class="icon-size-5 text-current">help
            </mat-icon>
          </button>
        </div>
        <ng-template [ngIf]="userTypes.includes('CUST')">
          <div tourAnchor="header.first">
            <button
              style="margin: 0 2px;display: flex;align-items: center;justify-content: center"
              mat-icon-button
              (click)="openSub()"
              xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
              <mat-icon>card_membership</mat-icon>
            </button>
          </div>

        </ng-template>
        <ng-template [ngIf]="userTypes.includes('CUST')">
          <div tourAnchor="header.second">
            <button  mat-icon-button (click)="openCustomerProgressDialog()" >
              <mat-icon
                class="icon-size-5 text-current">store
              </mat-icon>
            </button>
          </div>

        </ng-template>
        <notifications></notifications>
        <div style="position: relative">
        </div>

        <ng-template [ngIf]="showAttendanceIcon">
          <button
            style="margin: 0 2px;display: flex;align-items: center;justify-content: center"
            mat-icon-button
            (click)="openAttendancePanel()"
            #checkInOutOrigin xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
            <!--  <mat-icon>check_none</mat-icon>-->
<!--            <img class="material-icons checkIcon" [src]="empImage" alt="">-->

            <mat-icon
              class="icon-size-5 text-current">fingerprint
            </mat-icon>

          </button>
        </ng-template>
        <app-user-menu></app-user-menu>
        <app-languages></app-languages>
        <wifi-connection></wifi-connection>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

