<div style="height: 64px;width: 100% ;display: flex; justify-content: center;align-items: center">
  <div class="logo" routerLink="/">
    <img src="./assets/images/login-logo.png" style="margin: 0 3px;" alt=""/>
  </div>
</div>
<div *ngIf="!folded" class="row sideNavUserData">
  <div class="col-sm-12 center-sm">
    <h6 style="font-size: 16px; font-weight: bold; margin-bottom: 20px ;">
      {{ fullName }}
    </h6>


    <branch-search
      [data]="branches"
      [branchConfig]="branchConfig"
      (result)="branchChanged($event.value)"
    >
    </branch-search>

    <div class="release_and_image_container">
<!--      <mat-chip-list>-->
<!--        <mat-chip style="margin: -5px 0 2px" color="primary" selected (click)="openReleaseNotesDialog()">1.1.1-->
<!--        </mat-chip>-->
<!--      </mat-chip-list>-->
      <img [src]="userImagePath" onError="this.src='assets/images/user.png'" style="
        width: 72px;
        height: 72px;
        border-radius: 50%;
      " alt=""/></div>
  </div>
  <div class="col-sm-12 center-sm">
    <button (click)="navTo()"
            class="manage-account"
            [ngClass]="{'btn-is-active':activeScreenId == -1}">{{ 'SIDENAV.MANAGE_ACCOUNT' | translate }}
    </button>
  </div>
</div>
<div style="
    padding-top: 50px;
    margin-top: -48px;">
  <div *ngFor="let item of sideMenu">
    <div *ngIf="!folded" class="row item" (click)="toggleChildren(item?.id)"
         [class.rtl-item]="_translate.currentLang === 'ar'" [class.ltr-item]="_translate.currentLang !== 'ar'">
      <div class="col-sm-1 item-icon">
        <mat-icon>{{ item?.fontIcon }}</mat-icon>
      </div>
      <div class="col-sm-9 item-text">
        <span *ngIf="_translate.currentLang === 'ar'">{{ item?.nameAr }}</span>
        <span *ngIf="_translate.currentLang === 'en'">{{ item?.nameEn }}</span>
        <span *ngIf="_translate.currentLang === 'fr'">{{ item?.nameFr }}</span>
      </div>
      <div class="col-sm-2">
        <mat-icon class="arrow-icon"><span *ngIf="!item?.active">
            <span *ngIf="_translate.currentLang === 'ar'" style="font-size: 24px;">keyboard_arrow_left</span>
            <span *ngIf="_translate.currentLang !== 'ar'" style="font-size: 24px;">keyboard_arrow_right</span>
          </span>
          <span *ngIf="item?.active" style="font-size: 24px;">keyboard_arrow_down</span>
        </mat-icon>
      </div>
    </div>
    <div *ngIf="folded" class="row item" [matMenuTriggerFor]="subMenu"
         [class.rtl-item]="_translate.currentLang === 'ar'" [class.ltr-item]="_translate.currentLang !== 'ar'">
      <div class="col-sm-1 item-icon">
        <mat-icon>{{ item?.fontIcon }}</mat-icon>
      </div>
    </div>
    <div *ngIf="item?.screens?.length && item?.active"
         class="screensContainer" >
      <div *ngFor="let screen of item?.screens"
           class="row item sub-item"
           [class]="screen.id == activeScreenId ? 'active-item':''"
           [class.rtl-sub-item]="_translate.currentLang === 'ar'"
           [class.ltr-sub-item]="_translate.currentLang !== 'ar'"
           (click)="changeRoute(screen?.routingPath , screen.id)">
<!--        <div class="col-sm-1 item-icon" [class]="screen.id == activeScreenId ? 'active-item':''"-->
<!--             *ngIf="sideMenu.length <=2">-->
<!--          <mat-icon style="margin: 0 -20px;">{{ screen?.fontIcon }}</mat-icon>-->
<!--        </div>-->
        <div class="col-sm-12  naskh"
             [class.naskh]="_translate.currentLang === 'ar'">
          <span
            *ngIf="!folded && _translate.currentLang === 'ar'"> <span class="dot"></span> {{
              screen?.nameAr
            }}</span>
          <span
            *ngIf="!folded && _translate.currentLang === 'en'"> <span class="dot"></span> {{
              screen?.nameEn
            }}</span>
          <span
            *ngIf="!folded && _translate.currentLang === 'fr'"> <span class="dot"></span> {{
              screen?.nameFr
            }}</span>
        </div>
      </div>
    </div>
    <mat-menu #subMenu="matMenu">
      <button *ngFor="let screen of item?.screens" mat-menu-item
              [class]="screen.id == activeScreenId ? 'active-item':''"
              (click)="changeRoute(screen?.routingPath , screen.id)">
        <span *ngIf="_translate.currentLang === 'ar'">{{
            screen?.nameAr
          }}</span>
        <span *ngIf="_translate.currentLang === 'en'">{{
            screen?.nameEn
          }}</span>
        <span *ngIf="_translate.currentLang === 'fr'">{{
            screen?.nameFr
          }}</span>
      </button>
    </mat-menu>
  </div>
  <br>
</div>
<div class="social" *ngIf="!folded">
  <a style="cursor: pointer" (click)="openStoreDialog('appGallery')">
    <img src="./assets/images/icons/huaweiLogo.png" alt="appGallery">
  </a>
  <a style="cursor: pointer" (click)="openStoreDialog('appStore')">
    <img src="./assets/images/icons/appleLogo.png" alt="appStore">
  </a>
  <a style="cursor: pointer" (click)="openStoreDialog('googlePlay')">
    <img src="./assets/images/icons/googlePlay.png" alt="googlePlay">
  </a>
</div>

<div *ngIf="!folded" style="display: flex;justify-content: center;margin-bottom: 10px">
  <span style="color:#FFFFFF;cursor: pointer" (click)="openReleaseNotesDialog()">{{'COMMON.VERSION_NUMBER'| translate}} {{versionNumber}} </span>
</div>

<div style="display: flex;justify-content: center" *ngIf="!folded">
  <button (click)="logout()" mat-button color="primary">{{ 'TOOLBAR.LOGOUT' | translate }}
    <mat-icon>power_settings_new</mat-icon>
  </button>
</div>
<div *ngIf="folded">
  <button (click)="logout()" mat-button color="primary" style="display: flex">
    <mat-icon>power_settings_new</mat-icon>
  </button>
</div>

