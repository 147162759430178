import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {fromEvent} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {Constant, MY_DATE_FORMATS} from '../../constant/constant';
import {SearchConfiguration} from '../../modal/shared/search.configuration';
import {SearchRequest} from '../../modal/shared/searchCriteria';

@Component({
  selector: 'advanced-search-component',
  templateUrl: './advanced-search.component.html',
  styleUrls: [
    './advanced-search.component.scss',
    './advanced-search.media.component.scss',
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class AdvancedSearchComponent implements OnInit {
  @Input() searchConfig: any = new SearchConfiguration();
  @Input() placeholder: string = '';
  @Input() hasPrint: boolean = false;
  @Input() hasDoc: boolean = false;
  @Input() searchValue: any = {};
  @Output() searchValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() filterValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() printPdfValues: EventEmitter<any> = new EventEmitter<any>();
  @Output() printDocxValues: EventEmitter<any> = new EventEmitter<any>();
  @Output() subCategory: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectValue: EventEmitter<any> = new EventEmitter<any>();

  filterForm: UntypedFormGroup = new UntypedFormGroup({});
  fieldsControls: UntypedFormControl[];
  active: boolean;
  filterActive: boolean;
  queryRequest:SearchRequest = new SearchRequest();
  @ViewChild('search', { static: true }) search: ElementRef;

  constructor(
    public _translate: TranslateService,
    private constant: Constant,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.filterData();
    this.reactiveForm();
   if(this.route.snapshot.queryParams.searchCriteria){
    const params =  JSON.parse(this.route.snapshot.queryParams.searchCriteria);
    delete params.createdFromChannelId
    delete params.id
    delete params.statusCode
    delete params.itemRequestStatus
    this.filterForm.setValue(params);
    let select = this.searchConfig.fields.find(field => field.fieldType == "select");
    if (select){
      if(params[select.name]){
        this.getSubcategories(params[select.name])
      }
    }
   }

  }

  //reactive form
  reactiveForm() {
    this.filterForm = new UntypedFormGroup({});
    this.filterForm.addControl('name', new UntypedFormControl(null,Validators.nullValidator));
    if(!this.searchConfig.fields) return;
    this.searchConfig.fields.forEach((field) => {
      this.filterForm.addControl(`${field.name}`, new UntypedFormControl(null,Validators.nullValidator));
    });
  }
  checkSpace(event:any) {
    if(event.target.selectionStart === 0 && event .code === 'Space') {
      event.preventDefault()
    }
  }

  // Research began after the completion of writing
  filterData() {
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event),
        debounceTime(this.constant.seachTimeOut)
      )
      .subscribe((value) => {
        if (value.key === 'Shift' || value.key === 'Alt' || value.key === 'ArrowRight' || value.key === 'ArrowLeft' || value.key === 'ArrowUp' || value.key === 'ArrowDown' || (value.code === 'Space' && value.location === 0 )) {
          value.preventDefault()
          return;
        }
        let filterObject = this.filterForm?.value

        Object.keys(filterObject).map(function(key, index) {
          if (typeof filterObject[key] === 'string') {
            return  filterObject[key] =  filterObject[key].replace(/ +(?= )/g,'');
          }
        });
        this.searchValue = filterObject;
        this.searchValueChange.emit(filterObject);
        this.queryRequest.searchCriteria = filterObject;
        this.filterValue.emit(this.queryRequest);
      });
  }

  // open Search
  openSearch() {
    this.active = !this.active;
    if (this.filterActive == true) {
      this.closeSearch();
    }
  }

  // close Search
  closeSearch() {
    this.filterActive = false;
    this.active = false;
  }

  // get sub categories
  getSubcategories(id) {
    this.subCategory.emit(id);
  }

  // filter event
  filter() {
    this.queryRequest.searchCriteria = this.filterForm.value
    this.closeSearch();
    this.filterValue.emit(this.queryRequest);
  }

  selectData(id,name){
    this.selectValue.emit({id,name})
  }


  //return
  returnName(value, type, control) {
    if (type == 'select' || type == 'sub-select' || type == "item-select") {
      const item = this.searchConfig.fields.find(
        (field) => field.name == control
      );
      const name = item.options.find((option) => option.id == value);
      if(name){
        return (this._translate.instant(name[item.optionName]) || name?.name);
      }
    }
    return value;
  }

  // remove filter Item
  removeFilterItem(control) {
    this.filterForm.controls[control].setValue(null);
    this.queryRequest.searchCriteria =this.filterForm.value
    this.filterValue.emit(this.queryRequest);
  }

  // reset filter
  resetFilter() {
    this.filterForm = new UntypedFormGroup({});
    this.queryRequest.searchCriteria =this.filterForm.value
    this.reactiveForm();
    this.subCategory.emit(null);
    this.filterValue.emit(this.queryRequest);
  }

  printPdf() {
    this.queryRequest.searchCriteria = this.filterForm.value
    this.closeSearch();
    this.printPdfValues.emit(this.queryRequest);
  }

  printDoc() {
    this.queryRequest.searchCriteria = this.filterForm.value
    this.closeSearch();
    this.printDocxValues.emit(this.queryRequest);
  }

  getCustomerBranch(event){
    console.log(event.value);
  }

}
