import {TranslateService} from '@ngx-translate/core';
import {Component, ComponentFactoryResolver, ElementRef, EventEmitter, inject, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {BaseService} from '../../service/base.service';
import {CustomerProgressDialogComponent} from './customer-progress-dialog/customer-progress-dialog.component';
import {DialogPosition, MatDialog} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';
import {ComponentPortal, TemplatePortal} from '@angular/cdk/portal';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {EmployeeCheckInOutComponent} from '../../../pages/human-resources/employee-check-in-out/employee-check-in-out.component';
import {Router} from '@angular/router';
import {TourService} from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  public width: number;
  public screenWidth: any;
  empImage: string;
  headerStyle = this._base.headerStyle;
  prodHeaderStyle = this._base.prodHeaderStyle;
  production = this._base.production;
  sidenav: boolean = true;
  userTypes = JSON.parse(localStorage.getItem('CURRENT_USER')).userTypes?.map((userType)=> userType.code);
  currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
  showAttendanceIcon : boolean = this.userTypes.includes('EMP') || this.userTypes.includes('SUPERVISOR') || this.userTypes.includes('MANAGER') || this.userTypes.includes('CUST')
  public  tourService = inject(TourService);

  calcHeader(){
    const _sideNav = document.getElementById('pagesSideNavigation');
    const sideNav: HTMLElement = _sideNav as HTMLElement;
    this.width = screen.width - sideNav.clientWidth;
  }
  constructor(public _translate: TranslateService, private _base: BaseService,private dialog: MatDialog, private router: Router) {
    this.tourService.initialize([{
      anchorId: 'header.first',
      content: 'GUIDE.HEADER_STEP_1_DESC',
      title: 'GUIDE.HEADER_STEP_1',

    }, {
      anchorId: 'header.second',
      content: 'GUIDE.HEADER_STEP_2_DESC',
      title: 'GUIDE.HEADER_STEP_2',
    }],{
      enableBackdrop: true,
nextBtnTitle:'GUIDE.NEXT',
prevBtnTitle:'GUIDE.PREV',
endBtnTitle:'GUIDE.END',
      backdropConfig: {
        offset: 10,
      },
    });
    this.empImage = this.production ?  'assets/images/icons/finger_prod.png' : 'assets/images/icons/finger_dev.png';
  }
  start() {
    this.tourService.start()
  }
  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    if(this.currentUser.showTutorialGuide === true) {
      this.start();
    }
  }

  @ViewChild('fixedHeader') fixedHeader: ElementRef;

  ngAfterViewInit() {
    // this.width = this.fixedHeader.nativeElement.offsetWidth - 217;
    this.calcHeader();
  }



  toggleSideNav() {
    this.toggle.emit();
    this.calcHeader();
    this.sidenav = !this.sidenav;
  }

  openCustomerProgressDialog() {
    const dialogRef = this.dialog.open(CustomerProgressDialogComponent,{
    }).addPanelClass('progress_dialog');
  }

  openAttendancePanel() {
    this.dialog.open(EmployeeCheckInOutComponent).addPanelClass('attendance-dialog-container')
  }

  openSub(){
    this.router.navigate(['/pages/invoices/sub-prices']);
  }
}
