<div [dir]="_translate.currentLang == 'ar' ? 'rtl' : 'ltr'">
  <div class="row progress_dialog_header" style="align-items: center;justify-content: space-between">
    <p mat-dialog-title class="store_setup">{{'COMMON.STORE_SETUP' | translate}}</p>
    <div style="position: relative">
      <mat-spinner [strokeWidth]="8" mode="determinate" [value]="fullPercentage"></mat-spinner>
      <div class="percentage_text">{{fullPercentage}}%</div>
    </div>
  </div>
  <div mat-dialog-content class="progresses_container">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let module of modulesOfProgress" (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false"
      [ngClass]="module.progressPercentage ===100 ?'stage_done':''"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p style="font-size: 18px;font-weight: 500;">{{ 'PROGRESS.' + module.name | translate}}</p>
          </mat-panel-title>
          <mat-panel-description>
            <mat-progress-bar mode="determinate" [value]="module.progressPercentage"></mat-progress-bar>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="stage_of_progress row" *ngFor="let stage of module.progressStages; let i = index"
          [routerLink]="module.stagesLinks[i]"
               [queryParams]="module.name === 'BRANCHES' ? {id : branchId} : null"
               (click)="closeDialog(module.stagesLinks[i],module.name)"
          >
            <p>{{'PROGRESS.' + stage.code  | translate}}</p>
            <div style="width:25px;align-items: center" class="row">
              <div *ngIf="stage.flag === true">
              <img src="assets/images/icons/done.png" alt="">
              </div>
              <div *ngIf="stage.flag === false">
                <div *ngIf="_translate.currentLang == 'ar'">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </div>
                <div *ngIf="_translate.currentLang == 'en'">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
              </div>
            </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
<!--    <div class="stage_of_progress row" >-->
<!--      -->
<!--      <mat-icon >done</mat-icon>-->
<!--    </div>-->
  </div>
</div>
