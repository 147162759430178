import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import * as printJS from 'print-js';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'preview-download-pdf-dialog',
  templateUrl: './preview-download-pdf-dialog.component.html',
  styleUrls: ['./preview-download-pdf-dialog.component.scss']
})
export class PreviewDownloadPdfDialogComponent implements OnInit {
  pdfUrl: any;

  constructor(
    private pdfDialogRef: MatDialogRef<PreviewDownloadPdfDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public pdfData: any,
    public _translate: TranslateService,
    private sanitizer: DomSanitizer,
  ) {
    this.loadPdf(this.pdfData.blob);
  }

  ngOnInit(): void {
  }

  downloadPdf() {
    const url = URL.createObjectURL(this.pdfData.blob);
    let link = document.createElement('a');
    link.href = url;
    link.download = this.pdfData.filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  printPdf() {
    const url = URL.createObjectURL(this.pdfData.blob);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      onIncompatibleBrowser: () => {
        window.alert('Browser Not Compitable');
      }
    });
    window.URL.revokeObjectURL(url);
  }

  loadPdf(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function() {
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result);
      window.URL.revokeObjectURL(this.pdfUrl);
    }.bind(this);
    // window.URL.revokeObjectURL(this.pdfUrl);
  }

  close() {
    this.pdfDialogRef.close();
  }

}
