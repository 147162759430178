import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ApiInterceptor} from './api.interceptor';
import {LoaderInterceptor} from './loader.interceptor';


export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },


];
